.swipeable-list-item {
  max-height: 2000px;
}

.swipeable-list-item--remove {
  max-height: 0;
}

.loader {
  display: table;
  width: 40px;
  height: 40px;
  margin: 0 auto 20px;
  border-radius: 50%;
  border: 3px solid;
  border-color: rgb(255, 153, 76) transparent;
  animation: loader 1.2s cubic-bezier(0.3, 0.7, 1, 0.3) infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.end {
  display: table;
  margin: 20px auto 20px;
}