.card {
    overflow: hidden;
    min-height: 450px;
    max-width: 500px;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    width: 90%;
    margin: 20px auto;
}

.card-header {
    background-color: white;
    border: none;
    padding: 0;
    margin-bottom: 20px;
}

h6.card-text {
    font-weight: bold;
}

p.card-text {
    max-height: 1500px;
    overflow-y: visible;
}

.card-img {
    height: 200px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-color: inherit;
    background-image: url(https://res.cloudinary.com/d3/image/upload/c_scale,q_auto:good,w_1110/trianglify-v1-cs85g_cc5d2i.jpg);
}

/* First border-left-width setting is a fallback */
.card-img::after {
    position: absolute;
    content: '';
    top: 161px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-top-width: 40px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 545px;
    border-left-width: calc(575px - 5vw);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
}

.card-avatar img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
    position: absolute;
    top: 100px;
    left: 1.25rem;
    width: 100px;
    height: 100px;
}
