button.nav-link:hover {
    color: white;
    background-color: #0d6efd;
}

button.nav-link.trash  {
    color: #dc3545;
    background-color: white;
}

button.nav-link.trash.active, button.nav-link.trash:hover {
    color: white;
    background-color: #dc3545;
}

button.nav-link.archive  {
    color: #4ba72a;
    background-color: white;
}

button.nav-link.archive.active, button.nav-link.archive:hover {
    color: white;
    background-color: #4ba72a;
}